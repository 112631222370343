/* // NOTE: the order of these styles DO matter */

/* // Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
  border: 1px solid red;
}

/* // Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: #f1c27d;
  color: white;
}

/* // Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: #e3a75f;
  color: white;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  border-color: rgba(4, 5, 6, 0.25);
}

/* // Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #e6e6e6;
}

.SingleDatePicker,
.SingleDatePickerInput,
.DateInput {
  display: block;
  width: 100%;
}

.DateInput_input {
  border: 1px solid #e6e6e6;
  border-radius: 0.375rem;
  color: #040506;
  font-size: 1rem;
  font-weight: 400;
  z-index: 3;
  line-height: 1;
  padding-top: 0.5rem 0.75rem;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 150ms;
}

.custom_field_input .DateInput_input {
  height: 41px;
}

.DateInput_input:hover {
  background: #f7f7f7;
  border-color: #f7f7f7;
}

.DateInput_input:focus,
.DateInput_input:active {
  border-color: #f1c27d;
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: none;
  background: #ffffff;
}

.SingleDatePicker_picker {
  margin-left: 4px;
  z-index: 51;
}

.SingleDatePickerInput_clearDate {
  margin: 0px;
  margin-right: 5px;
  z-index: 3;
}

.SingleDatePickerInput__showClearDate {
  padding: 0px;
}

.SingleDatePicker .SingleDatePickerInput_1 {
  display: flex;
}

.DateInput_input__disabled {
  cursor: not-allowed;
}

.SingleDatePickerInput__showClearDate {
  padding-right: 0;
}

.DateInput_input__disabled {
  cursor: not-allowed;
}

.wrapperSelectDate {
  overflow: unset;
}

.hidden-date-input .DateInput_1,
.hidden-date-input .DateInput {
  display: none;
}

.hidden-date-input.shared .SingleDatePicker_picker_1 {
  @apply !top-7 !-left-24;
}

.hidden-date-input.private .SingleDatePicker_picker_1 {
  @apply !top-8 !-left-10;
}

.hidden-date-input .SingleDatePickerInput_calendarIcon {
  @apply m-0 p-0;
}

.hidden-date-input .SingleDatePickerInput {
  @apply bg-transparent;
}

.wrapperSelectDate ::placeholder,
.wrapperSelectDate input {
  @apply text-black-ink font-medium;
  text-indent: 32px;
}

@media screen and (max-width: 500px) {
  .wrapperSelectDate {
    overflow-y: auto;
  }
}

/**
 * Styles for the component DateField used on the public flow
 * */
#public-date-picker {
  @apply bg-background text-foreground text-xl border-foreground/20 placeholder-foreground/50 print:placeholder-transparent rounded-xl;
  @apply hover:bg-foreground/7 hover:border-transparent focus:bg-background focus:border-accent;
  @apply py-2.5 px-4;
}

/* clear default style from the parent elements */
.DateInput:has(#public-date-picker) {
  @apply bg-background;
}

/* styling the picker */
#public-date-picker-wrapper .SingleDatePicker_picker,
#public-date-picker-wrapper .DayPicker__horizontal,
#public-date-picker-wrapper .CalendarMonthGrid,
#public-date-picker-wrapper .CalendarMonth {
  @apply bg-background;
}

#public-date-picker-wrapper .DayPickerNavigation_button__default {
  @apply bg-background border-foreground/20;
}

#public-date-picker-wrapper .CalendarDay__default,
#public-date-picker-wrapper .CalendarDay__blocked_out_of_range,
#public-date-picker-wrapper .CalendarDay__blocked_out_of_range:active,
#public-date-picker-wrapper .CalendarDay__blocked_out_of_range:hover {
  @apply bg-background border-foreground/20 text-foreground;
}

#public-date-picker-wrapper .CalendarDay__default {
  @apply hover:bg-foreground/7;
}

#public-date-picker-wrapper .CalendarDay__selected {
  @apply bg-accent border-accent hover:bg-accent/80;
}

#public-date-picker-wrapper .CalendarMonth_caption {
  @apply text-foreground;
}

#public-date-picker-wrapper .DayPicker_weekHeader {
  @apply text-foreground/60;
}

#public-date-picker-wrapper .SingleDatePickerInput_clearDate__default {
  @apply hover:bg-foreground/7;
}

/** the animation seems very wierd, so we disable it from public flow */
#public-date-picker-wrapper .DayPicker_transitionContainer__horizontal {
  transition: none;
}

/** svg overwrites. It does not accept rgb format */
#public-date-picker-wrapper .DateInput_fangShape {
  fill: var(--color-background-hex);
}

#public-date-picker-wrapper .DayPickerNavigation_svg__horizontal {
  fill: var(--color-foreground-hex);
}

#public-date-picker-wrapper .SingleDatePickerInput_clearDate_svg {
  fill: var(--color-foreground-hex);
  opacity: 0.5;
}

/**
 * end of styles for the component DateField used on the public flow
 * */
