#hubspotSignUpForm {
  @apply text-white;
}
#hubspotSignUpForm .hs-dependent-field {
  @apply py-4;
}
#hubspotSignUpForm .hs-form-field {
  @apply flex flex-col gap-2;
}
#hubspotSignUpForm select {
  @apply bg-black-ink border-2 border-grey-150 hover:bg-grey-150 focus:ring-grey-250 rounded-lg hover:border-grey-250 hover:cursor-pointer block form-select w-full transition duration-150 focus:shadow-none ease-in-out sm:leading-6;
}

#hubspotSignUpForm .hs-form-checkbox {
  @apply py-0.5;
}
#hubspotSignUpForm .hs-form-checkbox label {
  @apply flex items-center;
}
#hubspotSignUpForm .hs-form-checkbox input {
  @apply border-2 mr-2 border-white rounded-sm transition duration-150 ease-in-out cursor-pointer bg-black;
}

#hubspotSignUpForm .hs-form-checkbox:hover {
  @apply cursor-pointer;
}

#hubspotSignUpForm .hs-form-checkbox input:focus {
  @apply ring-0 outline-none shadow-none ring-offset-0;
}

#hubspotSignUpForm .hs-submit input {
  @apply hidden;
}

#hubspotSignUpForm .hs-error-msg {
  @apply text-peach-950 text-sm;
}
#hubspotSignUpForm .hs-fieldtype-text {
  @apply mt-2;
}
#hubspotSignUpForm .hs-fieldtype-text input {
  @apply bg-black-ink border-grey-150 hover:bg-grey-150 focus:ring-grey-250 rounded-lg hover:border-grey-250 hover:cursor-pointer block w-full transition duration-150 focus:shadow-none ease-in-out sm:leading-6;
}
#hubspotSignUpForm .is-placeholder {
  @apply text-grey-500;
}
