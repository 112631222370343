@tailwind base;
@tailwind components;
@tailwind utilities;

/* Write your own custom base styles here! */
@font-face {
  font-family: "Roboto";
  font-weight: 300;
  src: url(./fonts/Roboto-Light.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: url(./fonts/Roboto-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: url(./fonts/Roboto-Medium.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Crimson Pro";
  font-weight: 300;
  src: url(./fonts/Crimson-Pro-Light.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Crimson Pro";
  font-weight: 400;
  src: url(./fonts/Crimson-Pro-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Crimson Pro";
  font-weight: 500;
  src: url(./fonts/Crimson-Pro-Medium.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Rubik";
  font-weight: 300;
  src: url(./fonts/Rubik-Light.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Rubik";
  font-weight: 400;
  src: url(./fonts/Rubik-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Rubik";
  font-weight: 500;
  src: url(./fonts/Rubik-Medium.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto Mono";
  font-weight: 400;
  src: url(./fonts/RobotoMono-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto Mono";
  font-weight: 500;
  src: url(./fonts/RobotoMono-Medium.ttf) format("truetype");
  font-display: swap;
}

/* Write you own custom component styles here */
@layer components {
  .btn-blue {
    @apply bg-blue-500 text-white font-bold py-2 px-4 rounded;
  }

  .bg-sign-up-welcome {
    @apply bg-no-repeat;
    background-image: url("/images/illustration_welcome_css.svg");
    background-position-x: calc((100vw - 416px) / 2);
    background-size: 416px 416px;
  }
}
.bg-blur-16 {
  backdrop-filter: blur(16px);
}

/* old tailwind-ui styles */
.form-input {
  @apply border-grey-300;
  @apply rounded-md;
}

.form-select {
  @apply rounded-md;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

/**
 * Stripe style need to be global CSS
 * */
.StripeElement--invalid {
  border: 1px solid #e46767 !important;
}

.StripeElement--focus {
  border: 1px solid #f1c27d !important;
}

/**
 * Stream chat overwrites
 * */
.str-chat__message .str-chat__reaction-list,
.str-chat__message-commerce .str-chat__reaction-list {
  z-index: 1 !important;
}

/**
 * Email thread styles
 * */
.email-thread a {
  text-decoration: underline;
  @apply text-blue-500;
}

.email-thread h1,
.email-thread h2,
.email-thread h3 {
  @apply font-medium;
}
.email-thread h1 {
  @apply text-2xl;
}
.email-thread h2 {
  @apply text-xl;
}
.email-thread h3 {
  @apply text-lg;
}

.email-thread ul,
.email-thread ol {
  @apply mt-2 ml-8;
}
.email-thread ul {
  list-style: disc;
}
.email-thread ol {
  list-style: decimal;
}

.email-thread blockquote {
  @apply border-l-[4px] pl-4 py-1;
}

.email-thread b,
.email-thread strong {
  @apply font-medium;
}

/** gmail generated html */
.email-thread font[size="4"],
.email-thread font[size="6"] {
  @apply font-medium;
}
.email-thread font[size="6"] {
  @apply text-2xl;
}
.email-thread font[size="4"] {
  @apply text-xl;
}
.email-thread font[size="1"] {
  @apply text-xs;
}

/** formatting history messsage div element with three dots */
.email-thread > .gmail_quote::after {
  content: "\00B7\00B7\00B7";
  display: inline-block;
  @apply bg-grey-900 text-black-ink leading-none text-xl px-2 rounded mt-2 cursor-pointer;
}
.email-thread > .gmail_quote > .gmail_attr,
.email-thread > .gmail_quote > .gmail_quote {
  display: none;
}

/* temporary solution targetting inline image attachments */
.email-thread img[src^="cid"] {
  display: none;
}

.image-placeholder {
  min-height: 48px;
  min-width: 48px;
  background: url("/images/spinner_web_96px_transparent.gif");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

/** hubspot */
#live-chat {
  position: fixed;
  height: 0;
  overflow: hidden;
  @apply shadow-equal-20 rounded-lg z-50 bottom-4 right-4;
}

#live-chat-close {
  color: white;
  position: absolute;
  display: flex;
  cursor: pointer;
  @apply w-5 h-5 text-white right-4 top-5 flex items-center justify-center;
}
#live-chat-close::before {
  content: "x";
}

#hubspot-conversations-inline-parent {
  width: 376px;
  height: 500px;
  border: none;
}
#hubspot-conversations-inline-parent iframe {
  height: 500px;
  width: 100%;
}

input.form-checkbox {
  @apply h-6 w-6 border-foreground/20 hover:border-transparent hover:bg-foreground/20 hover:cursor-pointer text-accent bg-background checked:bg-accent/80 focus:outline-none focus:shadow-none transition duration-150 ease-in-out focus:ring-0 focus:ring-offset-0;
}

input.form-checkbox.white {
  @apply border-grey-800  hover:border-grey-500 bg-white checked:bg-accent/80;
}

/**
 * Custom style for clear an input type=number appearance
 * It removes the arrows from the input
 * */
.clear-appearence::-webkit-inner-spin-button,
.clear-appearence::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.typing {
  @apply h-7 -mt-0.5 p-2.5 bg-grey-950 rounded-full;
}

.typing__dot {
  @apply float-left w-2 h-2 mx-1 bg-grey-500 rounded-full opacity-0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.ai-chat-message-receiver p,
.ai-chat-message-receiver ul {
  @apply my-4;
}
.ai-chat-message-receiver p:first-child {
  @apply mt-0;
}
.ai-chat-message-receiver p:last-child {
  @apply mb-0;
}
.ai-chat-message-receiver li {
  @apply list-disc ml-4;
}
.ai-chat-message-receiver a {
  @apply text-action-400 underline;
}
