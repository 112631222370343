/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@layer components {
  .webflow-content h1,
  .webflow-content h2,
  .webflow-content h3,
  .webflow-content h4,
  .webflow-content h5,
  .webflow-content h6 {
    @apply text-base;
  }
  .webflow-content strong {
    @apply font-medium;
  }

  .go-flow-container form {
    @apply max-w-lg;
  }
}
