.editor-component .ql-container {
  font-size: 1rem;
  font-family: "Roboto";
  position: relative;
}

.editor-component.read-only .ql-toolbar {
  @apply invisible hidden;
  /** this hack was added to prevent the focus on the toolbar and prevent auto-scroll */
  position: absolute;
  top: -1000000px;
  left: -1000000px;
}

.editor-component .ql-container strong {
  font-weight: 500;
}

.editor-component .ql-container.ql-bubble .ql-tooltip .ql-toolbar .ql-formats {
  @apply mx-3;
}

.editor-component
  .ql-container.ql-bubble
  .ql-tooltip
  .ql-toolbar
  .ql-formats
  button {
  padding: 1px 3px;
}
.editor-component .ql-tooltip .ql-tooltip-editor input {
  @apply ring-0;
}

.editor-component
  .ql-container.ql-bubble
  .ql-tooltip
  .ql-toolbar
  .ql-formats
  button.ql-active {
  @apply bg-grey-250;
  @apply rounded;
}

.editor-component .ql-container .ql-toolbar {
  @apply rounded-lg;
  @apply bg-grey-100;
}

.editor-component .ql-bubble .ql-fill,
.ql-bubble .ql-stroke.ql-fill {
  @apply fill-current text-grey-500;
}

.editor-component .ql-container .ql-stroke {
  @apply stroke-current text-grey-500;
}

.editor-component .ql-container h1 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-size: 20px;
}

.email-editor .ql-editor {
  min-height: 120px;
  padding: 12px;
  font-size: 16px;
  overflow: inherit;
}

.email-editor:hover:not(:focus-within) {
  @apply bg-grey-950;
  @apply border-white/0;
}

.editor-component .ql-editor.ql-blank::before {
  font-style: normal;
  left: 0;
}

.editor-component .ql-editor {
  padding: 0;
  line-height: 1.8;
  overflow: inherit;
}

.editor-component .ql-editor.focus-visible {
  overflow: visible;
}

.email-editor .ql-editor ul,
.email-editor .ql-editor ol,
.editor-component .ql-editor ul,
.editor-component .ql-editor ol {
  padding: 0;
}

.editor-component .ql-editor ul:not([data-checked]) > li::before {
  text-align: center;
  font-weight: bold;
}

.editor-component .ql-editor ul[data-checked] > li::before {
  height: 20px;
  width: 20px;
  content: "";
  @apply border-grey-500;
  @apply border;
  @apply border-2;
  @apply rounded;
  @apply shrink-0;
  position: absolute;
  top: 4px;
}

.editor-component .ql-editor ul[data-checked="true"] > li::before {
  /* TODO Check if its possible to use tailwind vars */
  @apply border-action-600;
}

.editor-component .ql-editor ul[data-checked="true"] > li::after {
  content: "";
  height: 12px;
  width: 7px;
  position: absolute;
  left: 7px;
  top: 6px;
  border-top: 3px solid;
  border-left: 3px solid;
  @apply border-action-600;
  border-radius: 2px;
  transform: rotateZ(225deg);
}

.ql-editor a {
  @apply text-blue-500;
}

/**
 * the class .with-overflow was created to fix the visibility
 * of the toolbar when the react-quill is opened inside a modal that needs an 
 * overflowY with scroll. Without it the toolbar will never be visible from 
 * the modal edges because the way the HTML is generated by the library
 * */
.with-overflow .ql-editor {
  @apply h-96 overflow-y-auto;
}

/**
 * overrites the default styles of the react-quill library from snow theme
 * */
.ql-container.ql-snow {
  @apply !border-grey-900 rounded-b-md;
}

.editor-component .ql-container.ql-snow {
  border: none;
  @apply mt-4;
}

.ql-container.ql-snow .ql-tooltip {
  @apply text-grey-500 shadow-lg border-grey-900 rounded-lg;
}
.ql-container.ql-snow a,
.ql-container.ql-snow .ql-tooltip a,
.ql-container.ql-snow .ql-tooltip a.ql-preview {
  @apply text-blue-500;
}

.ql-container.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  @apply rounded-md border-grey-900;
}

.ql-container.ql-snow .ql-remove {
  @apply !text-peach-500;
}

.ql-toolbar.ql-snow {
  @apply sticky top-0 z-10 bg-white !border-grey-900 rounded-t-md;
}

.sticky-toolbar-t-6 .ql-toolbar.ql-snow {
  @apply -top-6;
}

.sticky-toolbar-t-8 .ql-toolbar.ql-snow {
  @apply -top-8;
}

.editor-component .ql-toolbar.ql-snow {
  border: 0;
  @apply top-0;
}
